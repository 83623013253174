/**
 * External dependencies
 */
import React from 'react';
import { useSelect } from '@wordpress/data';

/**
 * Internal dependencies
 */
import { STORE_KEY } from '../../store';
import { AppStateNewsItem } from '../../store/types';

import './TopBanner.scss';

interface TopBannerState {
	items: AppStateNewsItem[];
	isFetching: boolean;
}

const TopBanner = () => {
	const {
		items = [],
		isFetching = true,
	}: TopBannerState = useSelect( ( select ) => {
		const {
			getNews,
			isNewsFetching,
		} = select( STORE_KEY );

		return {
			items: getNews(),
			isFetching: isNewsFetching(),
		};
	}, [] );

	if ( isFetching ) {
		return null;
	}

	if ( isFetching && ! items.length ) {
		return null;
	}

	const firstItem = items[ 0 ];

	if ( ! firstItem ) {
		return null;
	}

	return (
		<div className="top-banner">
			<div className="top-banner__inner">
				<div className="top-banner__item">
					<span className={ 'top-banner__title' }>
						{ firstItem.title }
					</span>
					<a href={ firstItem.read_more_url }>
						{ firstItem.read_more_label }
					</a>
				</div>
			</div>
		</div>
	);
};

export default TopBanner;
